import { ref } from "vue";
export default {
  name: "modal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: "不取消"
    },
    yesText: {
      type: String,
      default: "是的"
    }
  },

  setup(props, ctx) {
    // const show = props.show;
    // const title = props.title;
    const hideModal = () => {
      ctx.emit("hideModal");
    };

    const submit = () => {
      ctx.emit("submit");
    };

    return {
      hideModal,
      submit
    };
  }

};