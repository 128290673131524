import { Tag, Col, Icon, Cell, CellGroup, Toast, Tabbar, TabbarItem, NavBar } from "vant";
import modal from "@/component/modal.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { userCancel, getOrderList, qrCodeImage, checkSucceed } from "@/api/services.js";
export default {
  setup() {
    let router = useRouter();
    const active = ref(1);
    const phone = ref(router.currentRoute.value.query.phone);
    const pageTitle = ref("吃饭核销-" + phone.value);
    const tabBars = ref(["/book?phone=" + phone.value, "/check?phone=" + phone.value, "/admin?phone=" + phone.value]);
    return {
      active,
      phone,
      pageTitle,
      tabBars
    };
  },

  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    modal
  },

  data() {
    return {
      userCancel,
      showLoading: false,
      active: 1,
      showCancelDialog: false,
      cancelTitle: "取消报餐?",
      showQR: false,
      getOrderList,
      myOrderList: [],
      mealDateTextList: [],
      cancelMealDate: "",
      cancelMealType: "",
      cancelId: "",
      checkDetail: {
        mealType: "",
        statusText: "",
        timeDesc: ""
      },
      noData: false,
      qrCodeImage,
      imageUrl: "",
      checkSucceed,
      isScanSuccess: false,
      timer: null
    };
  },

  created() {
    this.getList();
  },

  methods: {
    hideQr() {
      this.showQR = false;
      this.getList();
    },

    getList() {
      var obj = {
        phone: this.phone
      };
      let that = this;
      this.getOrderList(obj).then(res => {
        if (res.data.code == 0) {
          that.noData = false;
          that.showLoading = false; //接收后台返回的数据，动态绑定到界面

          this.myOrderList = res.data.data;

          if (this.myOrderList && this.myOrderList.length > 0) {
            this.mealDateTextList = [];
            this.myOrderList.forEach(element => {
              if (element && element.mealDate) {
                if (!this.isMealDateExists(element.mealDate)) {
                  this.mealDateTextList.push(element.mealDate);
                }
              }
            });
          } else {
            this.myOrderList = [];
            this.mealDateTextList = [];
          }

          if (this.mealDateTextList.length <= 0) {
            that.noData = true;
          }
        } else {
          Toast("无法获取信息，请稍候重试");
        }
      }, error => {
        that.showLoading = false;
        console.log(error);
      });
    },

    getQrcodeImage(id) {
      var obj = {
        id: id
      };
      let that = this;
      this.qrCodeImage(obj).then(res => {
        // console.log("get qrcodeimage");
        // console.log(res);
        let blob = new Blob([res.data], {
          type: "image/jpeg"
        });
        that.imageUrl = window.URL.createObjectURL(blob);
        console.log(that.imageUrl);
      }, error => {
        console.log(error);
      });
    },

    isMealDateExists(date) {
      let flag = false;

      if (this.mealDateTextList && this.mealDateTextList.length > 0) {
        for (let i = 0; i < this.mealDateTextList.length; i++) {
          if (this.mealDateTextList[i] == date) {
            flag = true;
            break;
          }
        }
      }

      return flag;
    },

    getListByMealDate(date) {
      let result = [];

      if (this.myOrderList && this.myOrderList.length > 0) {
        for (let i = 0; i < this.myOrderList.length; i++) {
          if (this.myOrderList[i].mealDate == date) {
            if (this.myOrderList[i].timeDesc == null) {
              continue;
            }

            result.push(this.myOrderList[i]);
          }
        }
      }

      return result;
    },

    hideModal() {
      // 取消弹窗回调
      this.showCancelDialog = false;
      this.cancelMealDate = "";
      this.cancelMealType = "";
      this.cancelId = "";
    },

    //取消
    submit() {
      let obj = {
        id: this.cancelId
      };
      this.userCancel(obj).then(res => {
        if (res.data.code == 0) {
          //Toast("取消成功");
          this.hideModal();
          this.showCancelDialog = false;
          this.getList();
        }
      });
    },

    //检测扫码是否成功
    checkQrcodeStatus(id) {
      console.log("开始检测扫码状态..");
      let obj = {
        id: id
      };
      let that = this;
      this.checkSucceed(obj).then(res => {
        console.log("检测扫码状态返回为:" + res.data.data);

        if (res.data.code == 0 && res.data.data == 200) {
          this.isScanSuccess = true;
          clearInterval(that.timer);
        } else {
          this.isScanSuccess = false;

          if (that.showQR == false) {
            clearInterval(that.timer);
          }
        }
      });
    },

    onClick(detail) {
      if (detail && detail.statusText == "去核销") {
        //弹出待核销界面
        this.getQrcodeImage(detail.id);
        this.checkDetail = detail;
        this.showQR = true;
        let that = this; //开启轮休，每隔4秒到后台调接口，传递的参数，detail.id, 如果后台接口返回0，说明还没有扫码，继续等待下一个5秒；如果返回200，
        //setInterval的轮休要停止，同时切换Imageurl到成功的图片上

        this.timer = setInterval(function () {
          that.checkQrcodeStatus(detail.id);
        }, 4000);
      } else if (detail && detail.statusText == "取消") {
        this.cancelId = detail.id; //弹出取消确认界面

        this.showCancelDialog = true;

        if (detail.mealDate) {
          let dateStr = detail.mealDate.slice();
          let newDateStr = "";

          for (let i = 0; i < dateStr.length; i++) {
            if (i != 2) {
              newDateStr += dateStr[i];
            } else {
              newDateStr += "月" + dateStr[i];
            }
          }

          newDateStr += "日";
          this.cancelMealDate = newDateStr;
        }

        this.cancelMealType = detail.mealType;
      }
    },

    insertStr(soure, start, newStr) {
      return soure.slice(0, start) + newStr + soure.slice(start);
    },

    onClickRight() {
      this.$router.push({
        path: "/mealRecord",
        query: {
          phone: this.phone
        }
      });
    },

    onClickLeft() {
      this.showLoading = true;
      this.getList();
    }

  }
};