import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3bcdb3fb"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "modal-bg"
};
const _hoisted_2 = {
  class: "modal-container"
};
const _hoisted_3 = {
  class: "modal-header"
};
const _hoisted_4 = {
  class: "modal-main"
};
const _hoisted_5 = {
  class: "modal-footer"
};
const _hoisted_6 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_7 = {
  style: {
    "width": "50%",
    "text-align": "center",
    "border-right": "1px solid #ddd"
  }
};
const _hoisted_8 = {
  style: {
    "width": "50%",
    "text-align": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("table", _hoisted_6, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_7, [_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.hideModal && $setup.hideModal(...args))
  }, _toDisplayString($props.cancelText), 1)]), _createElementVNode("td", _hoisted_8, [_createElementVNode("div", {
    style: {
      "color": "red"
    },
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.submit && $setup.submit(...args))
  }, _toDisplayString($props.yesText), 1)])])])])])], 512)), [[_vShow, $props.show]]);
}